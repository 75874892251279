import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/loss_unable',
  component: Layout,
  redirect: '/loss_unable/page',
  name: 'loss_unable',
  meta: {
    title: '平仓不执行',
    i18n: 'route.tabbar',
    icon: 'game-icons:3d-glasses',
  },
  children: [
    {
      path: 'page',
      name: 'lossUnablePosition',
      component: () => import('@/views/loss_unable/position.vue'),
      meta: {
        title: '仓位',
        i18n: 'route.tabbar',
        menu: true,
        breadcrumb: true,
        activeMenu: '/loss_unable/position',
      },
    },
    {
      path: 'limit_order',
      name: 'lossUnableLimit',
      component: () => import('@/views/loss_unable/limit_order.vue'),
      meta: {
        title: '限价单',
        i18n: 'route.tabbar',
        menu: true,
        breadcrumb: true,
        activeMenu: '/loss_unable/limit',
      },
    },
  ],
}

export default routes
